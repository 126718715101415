@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.productCard {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s var(--transition-fn-smooth-in-out);
}

.productSlideCard {
  opacity: 0.4;
  &.active {
    opacity: 1;
  }
  @include tablet {
    opacity: 0;
    &.visible {
      opacity: 1;
    }
  }
}

.cardInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: var(--color-card-bg);
  border-radius: var(--palette-border-radius-xxl);
  overflow: hidden;
  isolation: isolate;
  transition-duration: 0.3s;
  transition-property: background-color, color;
  transition-timing-function: var(--transition-fn-smooth-in-out);

  &:hover {
    background-color: var(--palette-gray-700);

    .content {
      .title {
        color: var(--palette-white);
      }

      .description {
        color: var(--palette-gray-200);
      }
    }

    .image {
      transform: scale(1.05);
    }
  }
}

.content {
  position: relative;
  z-index: 1;
  padding: 20px 20px 0;
  width: 100%;
  user-select: none;

  @include tablet {
    padding: 24px 20px 0;
  }

  @include desktop {
    padding: 32px 24px 0;
  }
}

.title {
  @include typography.h4S_;
  @include desktop {
    @include typography.h4L_;
  }
}

.description {
  @include typography.bodyS_;
  margin-top: 8px;

  @include desktop {
    @include typography.bodyM_;
    margin-top: 12px;
  }
}

.image {
  position: relative;
  flex: 1;
  margin-top: 8px;
  user-select: none;
  transition: all 0.3s ease;

  img {
    object-fit: contain;
  }

  @include tablet {
    margin-top: 12px;
  }
}
